.aside {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
  background-color: #1e1e2d;
  padding: 0;
}

@media (min-width: 992px) {
  .aside {
    width: 140px;
  }
  .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
  }
  .aside .aside-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1b1b28;
    height: 120px;
    padding: 0 25px;
    flex-shrink: 0;
  }
  .aside .aside-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .aside-fixed .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
  }
  .aside-static .aside {
    position: relative;
    z-index: 1;
  }
}
@media (max-width: 991.98px) {
  .aside {
    display: none;
    width: 80px;
  }
  .aside .aside-logo {
    display: none;
  }
}
