@media (min-width: 992px) {
  .wrapper {
    padding-left: 140px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-xxl,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding: 0 30px;
  }

  .aside-enabled.aside-fixed .wrapper {
    padding-left: 140px;
  }
  .header-fixed .wrapper {
    padding-top: 65px;
  }
  .header-fixed.toolbar-fixed .wrapper {
    padding-top: 140px;
  }
}
@media (max-width: 991.98px) {
  .container,
  .container-xxl,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: none;
    padding: 0 15px;
  }

  .header-tablet-and-mobile-fixed .wrapper {
    padding-top: 55px;
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper {
    padding-top: 115px;
  }
}
@media (min-width: 992px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    height: 65px;
  }
  .header-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    height: 65px;
    padding: 0;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .header-fixed.toolbar-fixed .header {
    box-shadow: none;
  }
  .aside-enabled.aside-fixed.header-fixed .header {
    left: 140px;
  }
  .header .header-menu {
    display: flex;
  }
}
@media (max-width: 991.98px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    position: relative;
    z-index: 3;
  }
  .header-tablet-and-mobile-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    height: 55px;
    min-height: 55px;
    background-color: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .header {
    box-shadow: none;
  }
  .header .header-menu {
    display: none;
    z-index: 99;
  }
  .header .page-title {
    display: none !important;
  }
}
.toolbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 0.5rem;
}

@media (min-width: 992px) {
  .toolbar-fixed .toolbar {
    height: var(--mk-toolbar-height);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .toolbar-enabled:not(.toolbar-fixed) .toolbar {
    margin-bottom: 30px;
  }
  .header-fixed.toolbar-fixed .toolbar {
    padding: 0;
    top: 65px;
    border-top: 1px solid #eff2f5;
  }
  .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    left: 140px;
    transition: left ease;
  }
}
@media (max-width: 991.98px) {
  .toolbar-tablet-and-mobile-fixed .toolbar {
    height: var(--mk-toolbar-height-tablet-and-mobile);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  }
  .toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) .toolbar {
    margin-bottom: 15px;
  }
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .toolbar {
    padding: 0;
    top: 55px;
    border-top: 1px solid #eff2f5;
  }
  .toolbar .page-title[data-mk-swapper="true"] {
    display: none !important;
  }
}

/* Chart work start */

.apexcharts-bar-series
  .apexcharts-plot-series
  > .apexcharts-datalabels
  > .apexcharts-data-labels
  > text {
  font-size: 10px !important;
  font-weight: 200 !important;
}

.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  font-size: 10px !important;
  font-weight: 300 !important;
  text-shadow: #ffffff !important;
  box-shadow: none !important;
  /* color: #eff2f5 !important; */
}

.top-three-stops-chart > div > div > canvas {
  width: auto;
  height: 160px !important;
}
.oee-loss-chart > div > div > canvas {
  width: auto;
  height: 160px !important;
}
.machine-stops-chart > div > div > canvas {
  width: auto;
  height: 200px !important;
}

.bg-purple {
  background: #9400ff !important;
}

.bg-purple:hover {
  background: #8a00ed !important;
}
