.image-input {
  position: relative;
  display: inline-block;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input:not(.image-input-empty) {
  background-image: none !important;
}
.image-input .image-input-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 0.475rem;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
}
.image-input [data-kt-image-input-action="change"] {
  left: 100%;
  top: 0;
}
.image-input [data-kt-image-input-action="change"] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}
.image-input [data-kt-image-input-action="cancel"],
.image-input [data-kt-image-input-action="remove"] {
  position: absolute;
  left: 100%;
  top: 100%;
}
.image-input [data-kt-image-input-action="cancel"] {
  display: none;
}
.image-input.image-input-changed [data-kt-image-input-action="cancel"] {
  display: flex;
}
.image-input.image-input-changed [data-kt-image-input-action="remove"] {
  display: none;
}
.image-input.image-input-empty [data-kt-image-input-action="remove"],
.image-input.image-input-empty [data-kt-image-input-action="cancel"] {
  display: none;
}
.image-input.image-input-circle {
  border-radius: 50%;
}
.image-input.image-input-circle .image-input-wrapper {
  border-radius: 50%;
}
.image-input.image-input-circle [data-kt-image-input-action="change"] {
  left: 100%;
  top: 0;
  transform: translate(-100%, 0%);
}
.image-input.image-input-circle [data-kt-image-input-action="cancel"],
.image-input.image-input-circle [data-kt-image-input-action="remove"] {
  left: 100%;
  top: 100%;
  transform: translate(-100%, -100%);
}
.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid var(--kt-body-bg);
  box-shadow: var(--kt-box-shadow);
}
